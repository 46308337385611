<template>
  <div class="container">
    <el-swiper :src='imgSrc'
               :info="info"></el-swiper>
    <div class="container_ container1">
      <div class="main">
        <div class="part part1">
          <div class="text">
            <div>据调查，目前国内零售业和餐营业商家中约70%没有是用任何信息化系统，包括最基本的收银软件</div>
            <div class="text_1">智慧经营无疑是一种效率更高的经营方式</div>
            <div class="text_">
              <div class="i_icon"></div>
              <p>乐享小铺利用支付宝和微信两大平台的强势推广，结合商家本身需求，开发出一套完成的智慧经营系统。</p>
            </div>
            <div class="text_">
              <div class="i_icon"></div>
              <p>借助支付宝与微信双平台小程序，展示商家所有信息，利用会员、储值、领券等强大功能增加消费者粘性。</p>
            </div>
            <div class="text_">
              <div class="i_icon"></div>
              <p>根据商户经营需求，智能为其定制营销方案，为商家更好地拉新、拓客、锁客，搭建私域流量阵地，提升销售营业额。</p>
            </div>
          </div>
          <ul class="ulList">
            <li class="li_">
              <div class="bgImg bgImg1"></div>
              <div class="title">私域运营</div>
              <div class="dsc">粉丝社群运营工具</div>
            </li>
            <li class="li_">
              <div class="bgImg bgImg2"></div>
              <div class="title">积分营销</div>
              <div class="dsc">提升用户留存活跃</div>
            </li>
            <li class="li_">
              <div class="bgImg bgImg3"></div>
              <div class="title">会员管理</div>
              <div class="dsc">一站式数据体系</div>
            </li>
            <li class="li_">
              <div class="bgImg bgImg4"></div>
              <div class="title">刺激复购</div>
              <div class="dsc">协助门店引流锁客</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container_ container2">
      <div class="main">
        <div class="part part2">
          <div class="title">The private ordering</div>
          <div class="bgimg"></div>
          <div class="dsc">各行各业 私家定制</div>
          <ul class="pics">
            <li class="pic_li"
                @click="part2Click(index)"
                v-for="(item,index) in part2List"
                :key='item.info'>
              <div class="pic_div">
                <img class="img_"
                     :src="item.imgUrl"
                     alt="">
                <div class="bottom_info"
                     :class="['color_'+index]">{{ item.info }}</div>
              </div>
              <transition name="slide-fade">
                <div class="dscPic"
                     v-show="item.isShow">
                  <div class="triangle-down"
                       :class="['Scolor_'+index]"></div>
                  <img :src="item.dscImg"
                       alt="">
                </div>
              </transition>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container_ container3">
      <div class="main">
        <div class="part part3">
          <div class="title">Business circle</div>
          <div class="bgimg"></div>
          <div class="dsc">线上线下 商圈联动</div>
        </div>
      </div>
    </div>
    <div class="container_ container4">
      <div class="main">
        <div class="part part4">
          <div class="title">Generation of operating</div>
          <div class="bgimg"></div>
          <div class="dsc">全套代运营 一条龙服务</div>
          <ul class="part4_ul">
            <li class="ul_li li_first">
              <div class="meituan"></div>
              <div class="border_"></div>
              <div class="elem"></div>
            </li>
            <li class="ul_li">
              <div class="douyin"></div>
            </li>
            <li class="ul_li">
              <div class="qiwei">

              </div>
            </li>
            <li class="ul_li other_li">
              <div class="guanggao"></div>
              <div class="guanggao_text">广告代投放</div>
            </li>
            <li class="ul_li other_li">
              <div class="huodong"></div>
              <div>活动策划</div>
            </li>
            <li class="ul_li other_li">
              <div class="chanpin"></div>
              <div>
                <p>产品包装</p>
                <p>设计</p>
              </div>

            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container_ container5">
      <div class="main">
        <div class="part part5">
          <div class="title">The two versions</div>
          <div class="bgimg"></div>
          <div class="dsc">两大版本 自由选择</div>
          <div class="innerBox">
            <div class="tent1">
              <p>数字经营通用版</p>
              <p></p>
              <p>1000<span>元</span></p>
              <p>包含：</p>
              <p>微信/支付宝小程序各1个、</p>
              <p>独立管理后台、手机端管理后台</p>
            </div>
            <div class="tent2">
              <p>智慧营销定制版</p>
              <p></p>
              <p>6900<span>元</span></p>
              <p>包含：</p>
              <p>微信/支付宝/抖音/百度小程序选1、</p>
              <p>独立管理后台、营销组件、</p>
              <p>免费首页设计、免费主推产品设计</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc: require('@/assets/images/banner5.png'),
      info: {
        title: '智慧数字经营',
        dsc: '新媒体时代  让流量变现',
        number: ''
      },
      part2List: [
        { imgUrl: require('@/assets/images/lxxp_part2_1.png'), dscImg: require('@/assets/images/muying.png'), info: '母婴行业', isShow: false },
        { imgUrl: require('@/assets/images/lxxp_part2_2.png'), dscImg: require('@/assets/images/jiaoyu.png'), info: '教培行业', isShow: false },
        { imgUrl: require('@/assets/images/lxxp_part2_3.png'), dscImg: require('@/assets/images/meirong.png'), info: '美容行业', isShow: false },
        { imgUrl: require('@/assets/images/lxxp_part2_4.png'), dscImg: require('@/assets/images/lingshou.png'), info: '零售行业', isShow: false }
      ],
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    part2Click (index) {
      this.part2List[index].isShow = !this.part2List[index].isShow
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/lxxp.scss";
</style>